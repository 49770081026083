@use '@danfoss/mosaic/scss/mixins/common' as *;

.table-wrapper {
  overflow-x: auto;
}

.table {
  width: auto;
  table-layout: fixed;
  border-spacing: 0;
  text-align: left;
}

.sticky {
  position: sticky;
  left: 0;
}

.greyed {
  background-color: cssvar(color-neutral-weak);
}

.light {
  background-color: cssvar(color-primary);
}

.cell {
  min-width: 270px;
  padding: 16px 24px;
  border-right: 1px solid cssvar(color-neutral-base);
}

.name-cell {
  vertical-align: bottom;
}

.price-cell {
  vertical-align: top;
}

.select_wrapper {
  min-width: 270px;
}
