@use '@danfoss/mosaic/scss/mixins/common' as *;

.container {
  position: relative;
  z-index: 1;
}

.separator {
  border-bottom: 1px solid cssvar(color-neutral-base);
}
