@use '@danfoss/mosaic/scss/mixins/common' as *;
@use '@danfoss/mosaic/scss/mixins/media' as *;

.wrapper {
  margin-top: auto;
}

.container {
  background-color: cssvar(color-surface-base);
  border-radius: 2px;
  display: flex;
  justify-content: space-between;

  @include match-breakpoint-down('md') {
    flex-wrap: wrap;
  }
}

.description-section {
  align-items: center;
  display: flex;

  @include match-breakpoint-down('md') {
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 24px;
    width: 100%;
  }
}
