@use '@danfoss/mosaic-themes/dist/web/danfoss-orig/light/tokens';

.graphWrapper {
  cursor: pointer;
}

:export {
  red: tokens.$token-color-accent-base;
  grey: tokens.$token-color-neutral-strong;
  black: tokens.$token-color-object-strongest;
  orange: tokens.$token-color-warning;
  fontFamily: tokens.$token-font-weight-regular;
}
