@use '@danfoss/mosaic/scss/mixins/common' as *;

$min-height: 475px;

.content-wrapper {
  width: 965px;
  min-height: $min-height;
}

@include increase-specificity() {
  .selection-modal {
    :global(.df-modal-container) {
      max-width: fit-content;
    }

    :global(.df-modal-body) {
      position: relative;
      min-height: $min-height;
      padding: 0;
    }
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 0;
  cursor: pointer;
  z-index: 2;
}

.sidebar {
  width: 300px;
}

.filters {
  display: block;
  border-top: 1px solid cssvar(color-outline-base);

  &:last-of-type {
    border-bottom: 1px solid cssvar(color-outline-base);
  }
}
