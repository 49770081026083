@use '@danfoss/mosaic/scss/mixins/common' as *;

@include increase-specificity() {
  .tooltip-wrapper {
    :global(.df-tooltip-content) {
      position: relative;
      width: fit-content;
      padding: 40px 30px 35px 75px;
      max-width: 100%;
    }
  }
}

.height {
  position: absolute;
  top: 105px;
  left: 20px;
}

.width {
  position: absolute;
  top: 215px;
  left: 80px;
}

.length {
  position: absolute;
  top: 175px;
  left: 265px;
}
