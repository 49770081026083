.product-specs-container {
  padding: 40px 40px 20px;
  box-shadow: 0 0 18px hsl(0deg 0% 63% / 25%);
}

.specs-illustration {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 32px;
}
