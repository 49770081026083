@use '@danfoss/mosaic/scss/mixins/common' as *;
@use '@danfoss/mosaic-themes/dist/web/danfoss-orig/light/tokens';

$tooltip-handle-size: 10px;

.tooltip {
  top: 70%;
  position: absolute;
  background-color: cssvar(color-primary);
  padding: 25px;
  z-index: tokens.$token-index-navigation + 2;
  border-radius: 5px;

  &::before {
    content: '';
    position: absolute;
    left: calc(50% - $tooltip-handle-size / 2);
    top: -$tooltip-handle-size / 2;
    width: $tooltip-handle-size;
    height: $tooltip-handle-size;
    background-color: cssvar(color-primary);
    transform: rotate(45deg);
  }
}

@include increase-specificity() {
  .backdrop {
    background-color: transparent;
  }
}
