@use '@danfoss/mosaic/scss/mixins/common' as *;
@use '@danfoss/mosaic/scss/mixins/media' as *;

.wrapper {
  position: relative;
  border-width: 1px 1px 1px 0;
  border-style: solid;
  border-color: cssvar(color-neutral-base);
  box-shadow: 6px 1px 17px -5px rgb(66 78 84 / 24%);

  @include match-breakpoint-down('sm') {
    box-shadow: none;
    border-width: 1px;
  }
}

.add-wrapper {
  display: flex;
  justify-content: center;
  min-height: 150px;
}

.footer {
  background-color: cssvar(color-neutral-weak);
}
