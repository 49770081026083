.tooltip-wrapper {
  width: 300px;
}

$secondary-text-height: 50px;

.calculating-result {
  height: $secondary-text-height;
}

.calculating-loader {
  position: relative;
  height: $secondary-text-height;
  min-width: 160px;
}
