.container {
  min-height: 300px;
  position: relative;
}

.row {
  max-width: 360px;
}

.foo :global(.df-select-menu-list) {
  position: absolute;
}
