@use '@danfoss/mosaic/scss/mixins/common' as *;

.container {
  border-bottom: 1px solid cssvar(color-outline-base);
}

.description {
  flex: 1 1;
}

.title {
  line-height: 36px;
}
