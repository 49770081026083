@use '@danfoss/mosaic/scss/mixins/common' as *;

.product-item,
.add-item {
  position: relative;
  border-right: 1px solid cssvar(color-neutral-base);

  &:first-of-type {
    border-left: 1px solid cssvar(color-neutral-base);
  }
}

.add-item {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 130px;
}

.tooltip-wrapper {
  height: 24px;
}

.tooltip {
  width: 200px;
}
