@use '@danfoss/mosaic/scss/mixins/common' as *;

.content {
  position: relative;
}

.wrapper :global(.df-modal-container) {
  width: fit-content;
}

.button {
  &:hover,
  &:focus,
  &:active {
    path {
      fill: cssvar(color-active-base);
    }
  }
}
