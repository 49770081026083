@use '@danfoss/mosaic/scss/mixins/common' as *;
@use '@danfoss/mosaic/scss/mixins/media' as *;

.grid {
  display: grid;
  gap: 30px;
  width: 100%;

  grid-template-areas:
    'motor-1 motor-2 pump'
    'motor-3 motor-4 pump';
  grid-template-columns: repeat(3, max-content);

  @include match-breakpoint-down('md') {
    grid-template-areas:
      'motor-1 motor-2 .'
      'motor-3 motor-4 .'
      'pump pump pump';
    grid-template-columns: repeat(2, max-content) auto;
  }

  @include match-breakpoint-down('sm') {
    grid-template-areas:
      'motor-1'
      'motor-2'
      'motor-3'
      'motor-4'
      'pump';
    grid-template-columns: auto;
  }
}

.pump {
  grid-area: pump;

  position: relative;
  border-left: 1px solid cssvar(color-neutral-base);

  padding-left: 15px;

  @include match-breakpoint-down('md') {
    border-top: 1px solid cssvar(color-neutral-base);
    border-left: 0;
    padding-left: 0;
    padding-top: 15px;
  }
}

@for $i from 1 through 4 {
  .motor#{$i} {
    grid-area: motor-#{$i};
  }
}

.additional-motor {
  @include match-breakpoint-up('sm') {
    align-self: center;
  }
}

.motor,
.additional-motor-border {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    border-bottom: 1px solid cssvar(color-neutral-base);
    margin-top: 15px;
    inset: 100% 10%;

    @include match-breakpoint-down('sm') {
      inset-inline: 0;
    }
  }
}

.additional-motor-border {
  &::after {
    content: unset;
  }

  @include match-breakpoint-down('sm') {
    &::after {
      content: '';
    }
  }
}

.add-motor {
  @include match-breakpoint-down('sm') {
    justify-content: start;
  }
}
