@use '@danfoss/mosaic/scss/mixins/media' as *;
@use '@danfoss/mosaic/scss/mixins/common' as *;
@use '@danfoss/mosaic-themes/dist/web/danfoss-orig/light/tokens';

:global {
  .mfd-sticky-section {
    position: sticky;
    top: 72px;
    // need to override selection card loader
    z-index: 2;
    background-color: cssvar(color-primary);
    border-bottom: 1px solid cssvar(color-neutral-base);
    box-shadow: tokens.$token-shadow-strong;

    &:before,
    &:after {
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      background-color: cssvar(color-primary);
      border-bottom: 1px solid cssvar(color-neutral-base);
    }

    &:before {
      left: -100%;
    }

    &:after {
      right: -100%;
    }
  }
}

.container {
  position: relative;

  & :global(.df-loader) {
    z-index: 1;
  }
}

.loader-wrapper {
  height: 400px;

  @include match-breakpoint-down('md') {
    height: 100px;
  }
}

.compare-button-tooltip {
  width: 300px;
}
