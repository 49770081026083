@use '@danfoss/mosaic/scss/mixins/common' as *;

.config {
  &-wrapper {
    position: relative;
  }
}

@include increase-specificity() {
  .config-button {
    padding: 5px;
  }
}
