@use '@danfoss/mosaic/scss/mixins/media' as *;
@use '@danfoss/mosaic/scss/mixins/common' as *;

.title {
  @include match-breakpoint-down('md') {
    margin-top: 30px;
  }
}

@include increase-specificity() {
  .pump-speed-label {
    display: block;
    color: cssvar(color-object-strong);
  }
}
