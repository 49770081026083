@use '@danfoss/mosaic/scss/mixins/common' as *;

.header {
  text-align: start;
  padding-bottom: 10px;
}

.label {
  width: 150px;
  text-align: start;
  vertical-align: top;
}

.column {
  padding-left: 30px;
}

.input-displacement {
  width: 90px;
}

.measurement {
  width: 77px;
}

.error-container {
  height: 20px;
  max-width: 166px;
  white-space: nowrap;
  color: cssvar(color-accent-base);
}

.tooltip-container {
  white-space: normal;
}
