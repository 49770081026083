@use '@danfoss/mosaic/scss/mixins/media' as *;
@use '@danfoss/mosaic/scss/mixins/common' as *;

.main-img {
  position: absolute;
  left: 0;
  height: 232px;
  width: 100%;
  object-fit: cover;
}

.title {
  margin: 0;
  margin-bottom: 16px;

  @include match-breakpoint-down('xs') {
    margin-bottom: 8px;
  }
}

.description {
  max-width: 95%;

  @include match-breakpoint-down('xs') {
    max-width: 100%;
  }
}

@include increase-specificity(2) {
  .content {
    margin-top: 40px;
  }
}
