@use '@danfoss/mosaic/scss/mixins/common' as *;

.container {
  border: 1px solid cssvar(color-neutral-base);
  border-radius: 2px;
  height: 192px;
}

.loader {
  position: relative;
}

.top-section {
  position: relative;
}

.context-menu-icon {
  cursor: pointer;
}

.context-menu-icon-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.title-container {
  flex-grow: 1;
}

.title {
  overflow-wrap: anywhere;
}

.divider {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  flex-shrink: 0;
  background-color: cssvar(color-object-strongest);
}

@include increase-specificity() {
  .sending-loader-text {
    position: relative;
    z-index: 1;
    margin-top: 55px;
    color: cssvar(color-object-strongest);
    font-weight: 100;
  }
}
