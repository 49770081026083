.wrapper {
  position: relative;
}

.chart-container {
  position: relative;
  max-width: 900px;
}

.error {
  align-items: center;
}
