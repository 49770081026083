@use '@danfoss/mosaic/scss/mixins/common' as *;

.container {
  cursor: pointer;
}

.icon-container {
  width: 150px;
  height: 150px;
  border-radius: 12px;
  border: 1px solid cssvar(color-neutral-base);
  position: relative;

  & svg {
    transition: transform 0.2s;
  }

  &:hover {
    svg {
      transform: scale(1.2);
    }
  }
}

.selected {
  border-color: cssvar(color-object-base);
  background-color: cssvar(color-object-base);

  svg {
    filter: brightness(0) invert(1);
  }
}

.tooltip {
  display: inline-flex;
}
