@use '@danfoss/mosaic/scss/mixins/common' as *;

.wrapper-label {
  margin: 0 0 16px 0;
  padding: 0;
  cursor: pointer;
}

.container {
  min-width: 400px;
  border: 1px solid cssvar(color-neutral-base);
  border-radius: 2px;
  gap: 8px;

  & .label {
    gap: 24px;
  }
}

.selected {
  border-color: cssvar(color-object-strongest);
  background-color: cssvar(color-neutral-weak);
}
