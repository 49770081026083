@use '@danfoss/mosaic/scss/mixins/common' as *;
@use '@danfoss/mosaic/scss/mixins/media' as *;

.widget {
  position: relative;
  border-left: 1px solid cssvar(color-neutral-base);
  border-right: 1px solid cssvar(color-neutral-base);
}

@include increase-specificity() {
  .gear-box-item {
    display: flex;
    align-items: center;
    padding: 0;

    @include match-breakpoint-down('sm') {
      display: block;
    }
  }
}
