html,
body {
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: "myriad-pro";
  font-weight: 400;
  src: url("assets/fonts/MyriadPro-Regular.woff2") format("woff2"),
    url("assets/fonts/MyriadPro-Regular.woff") format("woff");
}

@font-face {
  font-family: "myriad-pro";
  font-weight: 700;
  src: url("assets/fonts/MyriadPro-Bold.woff2") format("woff2"),
    url("assets/fonts/MyriadPro-Bold.woff") format("woff");
}

@font-face {
  font-family: "myriad-pro";
  font-weight: 600;
  src: url("assets/fonts/MyriadPro-Semibold.woff2") format("woff2"),
    url("assets/fonts/MyriadPro-Semibold.woff") format("woff");
}

@font-face {
  font-family: "myriad-pro";
  font-style: italic;
  src: url("assets/fonts/MyriadPro-Italic.woff2") format("woff2"),
    url("assets/fonts/MyriadPro-Italic.woff") format("woff");
}
