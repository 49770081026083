.table {
  width: 100%;

  & :global(.df-responsive-table) {
    height: 450px;
  }

  & :global(.df-responsive-table.sc-df-table) {
    overflow-x: hidden;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
}
