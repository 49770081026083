@use '@danfoss/mosaic/scss/mixins/common' as *;

.option {
  border-top: 1px solid cssvar(color-neutral-base);
  cursor: pointer;

  &:hover {
    background-color: cssvar(color-neutral-weak);
  }
}

.selected {
  background-color: cssvar(color-neutral-weak);
}

.container {
  height: 450px;
}
