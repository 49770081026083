@use '@danfoss/mosaic/scss/mixins/media' as *;

.wrapper {
  @include match-breakpoint-down('sm') {
    flex-direction: column;
  }
}

.input {
  min-width: 100px;
  max-width: 200px;
}

.final-drive-efficiency {
  line-height: 40px;
}
