@use '@danfoss/mosaic/scss/mixins/common' as *;

.right-border {
  border-right: 1px solid cssvar(color-neutral-base);
}

.fieldset-small {
  width: 156px;
}

.error {
  position: absolute;
  margin-top: 2px;
}
