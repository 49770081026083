@use '@danfoss/mosaic/scss/mixins/common' as *;

.wrapper {
  display: inline-block;
  padding: 24px 48px;
  border: 1px solid cssvar(color-neutral-base);
}

.input {
  max-width: 104px;
}
