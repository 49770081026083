@use '@danfoss/mosaic/scss/mixins/media' as *;
@use '@danfoss/mosaic/scss/mixins/common' as *;

.title {
  @include match-breakpoint-down('md') {
    margin-top: 30px;
  }
}

.machine-operation-title {
  @include match-breakpoint-down('md') {
    margin-top: 30px;
  }
}

.operation-environment-options {
  @include match-breakpoint-down('lg') {
    flex-direction: column;
  }
}

@include increase-specificity() {
  .error {
    display: block;
  }
}

.input_wrapper {
  position: relative;
}
