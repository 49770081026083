@use '@danfoss/mosaic/scss/mixins/common' as *;
@use '@danfoss/mosaic/scss/mixins/media' as *;

.wrapper {
  display: flex;
  width: 83.5%;
  justify-content: space-between;
  background-color: cssvar(color-surface-base);

  @include match-breakpoint-down('md') {
    flex-wrap: wrap;
  }
}

.section {
  width: 44%;

  @include match-breakpoint-down('md') {
    width: 100%;
  }
}
