@use '@danfoss/mosaic/scss/mixins/common' as *;

@include increase-specificity() {
  .error {
    display: block;
  }
}

@include increase-specificity() {
  .input {
    text-align: left;
  }
}
