@use '@danfoss/mosaic/scss/mixins/common' as *;

.button {
  &:hover,
  &:focus,
  &:active {
    path {
      fill: cssvar(color-active-base);
    }
  }
}

.buttons_wrapper {
  width: fit-content;
}
