.input-item {
  width: 47%;
}

.input-label {
  display: flex;
}

.error {
  height: 16px;
}

.icon-button {
  cursor: pointer;
}
