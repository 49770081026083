@use '@danfoss/mosaic/scss/mixins/common' as *;

.content {
  position: relative;

  & :global(.df-loader) {
    z-index: 1;
  }
}

.table {
  overflow-y: auto;

  :global(.df-responsive-table) {
    height: auto;
  }
}

.main-section {
  display: flex;
  flex-direction: column;
  max-height: 490px;
  overflow: hidden;
}

.footer {
  border-top: 1px solid cssvar(color-neutral-base);
}
