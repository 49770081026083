@use '@danfoss/mosaic/scss/mixins/media' as *;
@use '@danfoss/mosaic/scss/mixins/common' as *;

.title {
  @include match-breakpoint-down('md') {
    margin-top: 30px;
  }
}

.radio-group {
  @include match-breakpoint-down('sm') {
    flex-direction: column;
  }
}

@include increase-specificity() {
  .buttons-group {
    button {
      white-space: nowrap;
      width: auto;
    }

    @include match-breakpoint-down('sm') {
      flex-direction: column;
    }
  }
}
