.chart-wrapper {
  position: relative;
}

.chart-legend-y {
  position: absolute;
  left: 0;
  transform: rotate(270deg);
}

.chart-legend-x {
  position: absolute;
  bottom: -30px;
}
