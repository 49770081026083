@use '@danfoss/mosaic/scss/mixins/common' as *;
@use '../../Widget.module' as widget;

.attribute-item {
  padding: widget.$section-spaces;

  &:nth-child(even) {
    background-color: cssvar(color-neutral-weak);
  }

  &:nth-child(odd) {
    background-color: #ffffff;
  }
}
