@use '@danfoss/mosaic/scss/mixins/common' as *;

.table {
  vertical-align: top;
}

.toggle {
  cursor: pointer;

  &:hover {
    color: cssvar(color-object-stronger);
  }
}

.collapsed-icon {
  transform: rotate(180deg);
}

.row-name {
  vertical-align: top;
}
