@use '@danfoss/mosaic/scss/mixins/common' as *;
@use '@danfoss/mosaic/scss/mixins/media' as *;

.motor-widget {
  position: relative;
  border-right: 1px solid cssvar(color-neutral-base);

  @include match-breakpoint-down('sm') {
    border-bottom: 1px solid cssvar(color-neutral-base);
    border-right: 0;
  }
}
